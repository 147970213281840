import React from "react"
import {NextComponentType} from "next"

// Constants
import {Routes} from "../constants"

// Services
import Router from "../services/Router/Router"

// Models
import {ReduxNextContext} from "./withRedux"

/**
 * @param BaseComponent
 */
export default function withDeviceTypeRedirect(BaseComponent: NextComponentType<any>) {
    function withDeviceTypeRedirectWrapper(props: any) {
        return <BaseComponent {...props}/>
    }

    withDeviceTypeRedirectWrapper.getInitialProps = async (ctx: ReduxNextContext) => {
        // @ts-ignore
        const {req, res} = ctx;

        if (typeof req !== "undefined") {
            // @ts-ignore
            const {device: {type}, url} = req;

            if (type === "phone" && false === url.includes("mobile")) {
                return Router.redirect(Routes.MOBILE, res)
            }

            if (type !== "phone" && url.includes("mobile")) {
                return Router.redirect(Routes.INDEX, res)
            }
        }

        return typeof BaseComponent.getInitialProps !== "undefined"
            ? await BaseComponent.getInitialProps({...ctx})
            : {}
    };

    return withDeviceTypeRedirectWrapper
}
