import React from "react";
import NextHead from "next/head";
import getConfig from "next/config"

export interface HeadProps {
    title: string
    description?: string
}

const Head: React.FC<HeadProps> = props =>  {
    const {title, description} = props;
    const {
      publicRuntimeConfig: {THEME},
    } = getConfig();
    const isUpolife = THEME === "upolife"

    const cardiolyseMeta = () => (
      <>
        <meta property="og:title" content="Cardiolyse App"/>
        <meta property="og:url" content="https://app.cardiolyse.com"/>
        <meta property="og:image" content="https://app.cardiolyse.com/static/assets/cardiolyse/logo-vertical@3x.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="/static/cardiolyse/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="96x96" href="/static/cardiolyse/favicon-96x96.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="/static/cardiolyse/favicon-16x16.png"/>
        <link rel="icon" href="/static/cardiolyse/favicon.ico"/>
      </>
    )

    const upolifeMeta = () => (
      <>
        <meta property="og:title" content="Upolife App"/>
        <meta property="og:url" content="https://app.upolife.com"/>
        <meta property="og:image" content="https://app.upolife.com/static/assets/upolife/logo.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="/static/upolife/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="96x96" href="/static/upolife/favicon-96x96.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="/static/upolife/favicon-16x16.png"/>
        <link rel="icon" href="/static/upolife/favicon.ico"/>
      </>
    )

    return (
        <NextHead>
            <meta charSet="utf-8"/>
            {typeof description !== "undefined" && <meta name="description" content={description}/>}
            <meta name="viewport" content="width=device-width, initial-scale=1"/>
            <meta property="og:type" content="product"/>
            <meta
              property="og:description"
              content="Affordable real-time remote heart health monitoring and analysis to reduce the healthy life treatment maintenance costs for insurance companies, hospitals and pharma."
            />
            {isUpolife ? upolifeMeta() : cardiolyseMeta()}
            <meta property="og:image:width" content="360"/>
            <meta property="og:image:height" content="234"/>
            <title>{title}</title>
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap"/>
        </NextHead>
    );
}

export default Head